<template>
  <div id="ledger">
    <el-dialog
      :title="ledgerFormTitle"
      width="680px"
      :visible.sync="ledgerDialogVisible"
      :close-on-click-modal="false"
      @close="ledgerDialogClose"
    >
      <el-form
        ref="ledgerFormRef"
        :model="ledgerForm"
        :rules="ledgerFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="materialName">
              <el-input v-model="ledgerForm.materialName" placeholder="请输入商品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="物资类别" prop="type">
              <el-input v-model="ledgerForm.type" placeholder="请输入物资类别" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="型号规格" prop="spec">
              <el-input v-model="ledgerForm.spec" placeholder="请输入型号规格" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="ledgerForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单价" prop="unitPrice">
              <el-input v-model="ledgerForm.unitPrice" placeholder="请输入单价" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="ledgerForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="金额" prop="amount">
              <el-input v-model="ledgerForm.amount" placeholder="请输入金额" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂家" prop="factory">
              <el-input v-model="ledgerForm.factory" placeholder="请输入厂家" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="采购日期" prop="purchaseDate">
              <el-date-picker v-model="ledgerForm.purchaseDate" placeholder="请选择采购日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到货日期" prop="deliveryDate">
              <el-date-picker v-model="ledgerForm.deliveryDate" placeholder="请选择到货日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="质量/采购协议" prop="agreement">
              <el-radio-group v-model="ledgerForm.agreement">
                <el-radio :label="1">
                  有
                </el-radio>
                <el-radio :label="2">
                  无
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input v-model="ledgerForm.remarks" placeholder="请输入备注" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="ledgerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="ledgerFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="商品名称">
        <el-input v-model="searchForm.materialName" placeholder="请输入商品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <!-- <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="ledgerPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="materialName" label="商品名称" />
      <el-table-column prop="type" label="物资类别" />
      <el-table-column prop="spec" label="型号规格" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="unitPrice" label="单价" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="amount" label="金额" />
      <el-table-column prop="factory" label="厂家" />
      <el-table-column label="采购日期">
        <template slot-scope="scope">
          <span v-if="scope.row.purchaseDate">{{ scope.row.purchaseDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="到货日期">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryDate">{{ scope.row.deliveryDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="质量/采购协议">
        <template slot-scope="scope">
          <span v-if="scope.row.agreement === 1">有</span>
          <span v-if="scope.row.agreement === 2">无</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CG_LEDGER_DELETE'])"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <!-- <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button> -->
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="ledgerPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addLedger,
  deleteLedger,
  updateLedger,
  selectLedgerInfo,
  selectLedgerList
} from '@/api/purchase/ledger'

export default {
  data () {
    return {
      ledgerDialogVisible: false,
      ledgerFormTitle: '',
      ledgerForm: {
        id: '',
        materialName: '',
        type: '',
        spec: '',
        batchNo: '',
        unitPrice: '',
        quantity: '',
        amount: '',
        factory: '',
        purchaseDate: '',
        deliveryDate: '',
        agreement: '',
        remarks: ''
      },
      ledgerFormRules: {
        materialName: [{ required: true, message: '商品名称不能为空', trigger: ['blur', 'change']}]
      },
      ledgerPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        materialName: ''
      }
    }
  },
  created () {
    selectLedgerList(this.searchForm).then(res => {
      this.ledgerPage = res
    })
  },
  methods: {
    ledgerDialogClose () {
      this.$refs.ledgerFormRef.resetFields()
    },
    ledgerFormSubmit () {
      if (this.ledgerFormTitle === '采购台账详情') {
        this.ledgerDialogVisible = false
        return
      }
      this.$refs.ledgerFormRef.validate(async valid => {
        if (valid) {
          if (this.ledgerFormTitle === '新增采购台账') {
            this.ledgerForm.id = ''
            await addLedger(this.ledgerForm)
          } else if (this.ledgerFormTitle === '修改采购台账') {
            await updateLedger(this.ledgerForm)
          }
          this.ledgerPage = await selectLedgerList(this.searchForm)
          this.ledgerDialogVisible = false
        }
      })
    },
    // handleAdd () {
    //   this.ledgerFormTitle = '新增采购台账'
    //   this.ledgerDialogVisible = true
    // },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteLedger(row.id)
        if (this.ledgerPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.ledgerPage = await selectLedgerList(this.searchForm)
      })
    },
    // handleUpdate (index, row) {
    //   this.ledgerFormTitle = '修改采购台账'
    //   this.ledgerDialogVisible = true
    //   this.selectLedgerInfoById(row.id)
    // },
    handleInfo (index, row) {
      this.ledgerFormTitle = '采购台账详情'
      this.ledgerDialogVisible = true
      this.selectLedgerInfoById(row.id)
    },
    selectLedgerInfoById (id) {
      selectLedgerInfo(id).then(res => {
        this.ledgerForm.id = res.id
        this.ledgerForm.materialName = res.materialName
        this.ledgerForm.type = res.type
        this.ledgerForm.spec = res.spec
        this.ledgerForm.batchNo = res.batchNo
        this.ledgerForm.unitPrice = res.unitPrice
        this.ledgerForm.quantity = res.quantity
        this.ledgerForm.amount = res.amount
        this.ledgerForm.factory = res.factory
        this.ledgerForm.purchaseDate = res.purchaseDate
        this.ledgerForm.deliveryDate = res.deliveryDate
        this.ledgerForm.agreement = res.agreement
        this.ledgerForm.remarks = res.remarks
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectLedgerList(this.searchForm).then(res => {
        this.ledgerPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectLedgerList(this.searchForm).then(res => {
        this.ledgerPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectLedgerList(this.searchForm).then(res => {
        this.ledgerPage = res
      })
    }
  }
}
</script>

<style>
</style>
